.subview
{
    position: relative;
    width: 100%;
}
.subview .sub_menu{
    position: relative;
    width: 100%;
    margin-bottom: -6px;
    z-index: 2;
    padding-top: 10px;
    
}
.subview .sub_menu .subBtn{
    display: inline-block;
    border: 1px solid grey;
    padding: 0 10px 0 10px;
    margin-left: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 40px;
    line-height: 40px;
    width: auto;
    background-color: #ABA4A4;
    padding-left: 3px;
    font-size: 17px;
    cursor: pointer;
    span.label{
        background-color: transparent;
        color: #000;
        cursor: pointer;
    }
}
.subview .sub_menu .subBtn.active{
    border-bottom: 1px solid white;
    background-color: white;
}
.subview .sub_container {
    z-index: 1;
    position: relative;
    margin: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 15px;
    border: 1px solid grey;
    background-color: white;
}
.subview .sub_container .sub {
    display: none;
    label{
        width: 20%;
        display: inline-block;
        text-align: right;
    }
    input, textarea{
        width: 79%;
        display: inline-block;
    }
}
.subview .sub_container .sub.active {
    display: block;
}