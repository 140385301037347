@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-progress-element;
@include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

@import "partial/icon";
@import "partial/login";
@import "partial/tabs";
@import "partial/subs";

#top-bar.top-bar{
	display: none;
	z-index: 3;
	height: 41px;
	//@include breakpoint(small only) {
	@media screen and (max-width: 769px){
		display: block;
	}
	.menu{
		background-color: transparent;
	}
	background-color: $black;
	color: $white;
	a{
		color: inherit;
	}
	li{
		position: relative;
		ul{
			right: 5px;
			top: 60px;
			display: none;
			position: absolute;
			list-style: none;
			background-color: $black;
			z-index: 5;
			&.display{
				display: block;
			}
			li{
				padding: 10px 10px;
				border-bottom: 1px solid $light-gray;
				font-weight: 500;
				a{
					display: block;
					line-height: 46px;
					padding: 0 20px;
					white-space: nowrap;

				}
				&:hover{
					background-color: lighten($black,15%);
				}
				&:last-child{
					border-width: 0;
				}
			}
		}

	}
	.menu-icon{
		visibility: hidden;
		//@include breakpoint(small only) {
		@media screen and (max-width: 769px){
			visibility: visible;
		}
	}
	button#pref{
		background-color: transparent;
	}
}
.panel{
	width: 100%;
	height: calc(100% - 46px);
	overflow-y: auto;
	position: absolute;
	top: 46px;
	left: 0;
	transition: left .5s;
	&.left{
		left: -100%;
	}
	&.right{
		left: 100%;
	}
}
#menu{
	content: 2;
	width: 255px;
	background-color: $medium-gray;
	border-right: 1px solid $black;
	top: 0;
	height: 100%;
	//@include breakpoint(small only) {
	@media screen and (max-width: 769px){
		height: calc(100vh - 41px);
		top: 41px;
	}
	display: block;
	position: absolute;
	overflow: hidden;
	
	left: 0;
	z-index: 2;
	//@include breakpoint(small only) {
	@media screen and (max-width: 769px){
		left: -255px;
		transition: left .5s;
		&.display{
			left: 0;
		}
	}
	ul{
		list-style: none;
		margin: 0;
		li{
			line-height: 48px;
			border-bottom: 1px solid $black;
			background-color: $dark-gray;
			background-color: darken($medium-gray,15%);
 			background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
 			background-blend-mode: lighten;
			transition: background-color 0.2s;
			position: relative;
			width: 100%;
			margin: 0;
			padding: 0;
			font-weight: 500;
			a{
				color: $black;
				display: block;
				padding: 0 30px 0 48px;
				&:hover{
					color: $white;
				}
			}
			&.sub:after{
				display: block;
				position: absolute;
				content: '>';
				right: 10px;
				top: 0;
				cursor: pointer;
			}
			&:hover, &.active{
				background: #C9CCD3;
				
				a{
					color: $white;
				}
			}

		}
	}
}
#page{
	z-index: 1;
	position: absolute;
	top: 0;
	height: 100%;
	//@include breakpoint(small only) {
	@media screen and (max-width: 769px){
		height: calc(100vh - 41px);
		top: 41px;
	}
	left: 255px;
	right: 0;
	overflow: hidden;
	.panel{
		height: calc(100% - 46px);
		top: 46px;
	}
	//@include breakpoint(small only) {
	@media screen and (max-width: 769px){
		left: 0;
	}
	
}
.return-container{
	background-color: $dark-gray;
	border-bottom: 1px solid $black;
	height: 46px;
	text-align: center;
	line-height: 46px;
	font-weight: 500;
	.menu{
		background: transparent;
		position: relative;
		height: 46px;
		button#pref{
			background-color: transparent;
			height: 46px;
			line-height: 1;
			color: $black;
		}
		ul{
			right: 0;
			top: 46px;
			display: none;
			position: absolute;
			list-style: none;
			background-color: $dark-gray;
			z-index: 5;
			border: 1px solid $black;
			border-top-width: 0;
			&.display{
				display: block;
			}
			li{
				padding: 0;
				border-bottom: 1px solid $black;
				background-color: darken($medium-gray,15%);
 				background-image: linear-gradient(-180deg, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%);
 				background-blend-mode: lighten;
				font-weight: 500;
				a{
					text-align: left;
					display: block;
					line-height: 48px;
					padding: 0 30px;
					white-space: nowrap;
					color: $black;

				}
				&:hover{
					
					background: #C9CCD3;
				}
				&:last-child{
					border-width: 0;
				}
			}
			
		}
	}
	.return{
		margin-bottom: 15px;
		cursor: pointer;
		left:20px;/*change the position*/
	    top:10px;/*change the position*/   
	    position:absolute;
	    background-repeat:repeat-x;
	    background-color: $dark-gray;
	    background-image : -webkit-linear-gradient(
	        bottom,
	        rgba(0,0,0,0) 0%,
	        rgba(0,0,0,0) 50%,
	        rgba(255,255,255,0.1) 50%,
	        rgba(255,255,255,0.3) 100%
	        );
	    height:30px;
	    padding: 0 10px 0 8px;
	    z-index : 0;
	    border-radius: 6px;
	    -webkit-border-top-left-radius : 10px 15px;
	    -webkit-border-bottom-left-radius : 10px 15px;
	    border-bottom: 1px solid rgba(255,255,255,0.3);
	    box-shadow :-1px -1px 0px rgba(0,0,0,0.2)inset,
	        0 1px 2px rgba(0,0,0,0.8)inset;
	    font-size : 11px;
	    display : block; 
	    text-align:center;
	    color:#fff;
	    text-shadow : 0px -1px 0px #000;
	    line-height : 30px;
	    font-family : HelveticaNeue;
	    font-weight: 700;
	    -webkit-background-size : 30px;
		&:before{
		    position:absolute;
		    top:9.9%;
		    left:-5px;
		    -webkit-background-size : 22px 22px;
		    background-position :-2px -1.5px;
		    background-color: $dark-gray;
		    background-image :  
		        -webkit-gradient(linear, left bottom, right top, 
		            from(rgba(0,0,0,0)), 
		            color-stop(0.5, rgba(0,0,0,0)), 
		            color-stop(0.5, rgba(255,255,255,0.1)), 
		            to(rgba(255,255,255,0.3))); 
		    height : 25px;
		    width: 25px;
		    -webkit-transform : rotate(-45deg) skew(-10deg, -10deg);
		    -webkit-border-top-right-radius : 100px 40px;
		    -webkit-border-top-left-radius : 30px 2px;
		    -webkit-border-bottom-right-radius : 2px 30px;
		    -webkit-border-bottom-left-radius : 40px 100px;
		    z-index : 1;
		    content : ' ';
		    border-left : 1.5px solid rgba(255,255,255,0.3);
		    box-shadow :  1px 1px 0 rgba(0,0,0,0.2) inset,
		        -1px 1px 1px rgba(0,0,0,0.5) inset;
		    -webkit-mask-image : 
		        -webkit-gradient(linear, left top, right bottom,
		            from(#000000), 
		            color-stop(0.33,#000000), 
		            color-stop(0.5, transparent), 
		            to(transparent));
		}
		display: none;
		&.display{
			display: block;
		}
	}
}
.row{
	margin: 0;
	width: 100%;
	max-width: 100%;
}
.transferring{
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
  	justify-content: center;
	background-color: rgba(0,0,0,.8);
	transition: all 0.25s;
	opacity: 0;
	&.display{
		display: flex;
		&.show{
			opacity: 1;
		}
	}
	.transferring-content{
		color: $white;
		.spinning{
			font-size: 200%;
			text-align: center;
			animation-name: spinning_anim;
    		animation-duration: 1s;
    		animation-iteration-count: infinite;
    		animation-timing-function: linear;
		}
	}
}
@keyframes spinning_anim {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
}
.submit{
	background-color: $primary-color;
	color: #FFF;
	line-height: 2;
	border-width: 0;
}
#i_save{
	display: none;
}
.padding{
	padding: 10px; 
}
.clean{
	label{
		width: 30%;
		display: inline-block;
		text-align: right;
		padding-right: 5px;
		vertical-align: top;
	}
	input, textarea, select{
		width: 69%;
		display: inline-block;
	}
}
div.bouton{
	background-color: $primary-color;
	color: $white;
	text-align: center;
	border-radius: 10px;
	padding: 0 10px;
	line-height: 2;
	cursor: pointer;
}
table tbody tr:nth-child(odd){
	background-color: lighten($primary-color, 50);
}
table.list tbody{
	tr:nth-child(even):hover{
		background-color: darken($table-striped-background,10);
	}
	tr:nth-child(odd):hover{
		background-color: lighten($primary-color,40);
	}
}
table.cke_dialog tbody tr{
	background-color: transparent;
}
.handle{
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: #000;
	margin: 0 10px;
	vertical-align: middle;
	cursor: move;
} 
fieldset{
	border: 1px solid #c0c0c0;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}