#login{
	
	width: 100%;
	height: 100vh;
	display: flex;
  	align-items: center;
  	justify-content: center;
  	background-color: $dark-gray;
  	#login-box{
  		max-width: calc(100vw - 50px);
  		width: 400px;
  		height: auto;
  		background-color: $light-gray;
  		border: 1px solid $black;
  		border-radius: 20px;
  		box-shadow: -3px 5px 10px 0px rgba(0,0,0,0.75);
  		transition: width .8s, height .8s, opacity .8s;
  		&.hidden{
  			width: 0;
  			height: 0;
  			opacity: 0;

  		}
  		h1{
  			background-color: $black;
  			color: $white;
  			border-top-left-radius: 20px;
  			border-top-right-radius: 20px;
  			font-size: 170%;
  			text-align: center;
  			padding: 10px 5px;
  			margin: 0;
  			text-transform: capitalize;
  		}
  		&>div{
  			display: flex;
  			align-items: center;
  			div{
  				display: flex;
  				align-items: center;
  				width: 40%;
  				padding: 90px 20px;
  				justify-content: center;
  				background-color: #FFF;
  				border: 1px solid $black;
	  			border-width: 0 1px 0 0;
	  			border-bottom-left-radius: 20px;
	  			
	  		}
	  		form{
	  			flex: flex-grow;
	  			flex-basis: auto;
	  			width: 100%;
	  			margin: 10px;
	  			input{
	  				width: 100%;
	  			}
	  			
	  		}
  		}
  		
  	}
}